import React from "react"
import classNames from 'classnames';
import { graphql, Link } from "gatsby"

export default function Banner(props) {
  return (
    <section className="section banner has-text-centered" style={{backgroundImage: `url(${props.imageUrl})`}}>
      <div class="container" style={{height: "100%"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
          <div>
            <h1 class="title is-size-1 is-size-2-mobile has-text-white">{props.title}</h1>
          </div>
        </div>
      </div>
    </section>
  )
}