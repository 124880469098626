import React from "react"
export default function Footer() {
  return (
    <footer className="section">
        <a className="is-size-5 social-link transition" href="mailto:hey@charliequinn.ca">Email</a>
        <a className="is-size-5 social-link transition" href="https://www.instagram.com/notenoughquinn/">Instagram</a>
        <a className="is-size-5 social-link transition" href="https://twitter.com/notenoughquinn">Twitter</a>
        <p className="column is-size-6 copyright">© 2021 Charlie Quinn. All rights reserved.</p>
    </footer>
  )
}