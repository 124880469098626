import React from "react"
import { graphql, Link } from "gatsby"
import {Helmet} from "react-helmet";

export default function Header() {
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <div>
      <Helmet>
        <html className="has-navbar-fixed-top"/>
        <meta charset="utf-8" />
        <title>The Portfolio of Charlie Quinn</title>
        <meta name="description" content="An independent graphic designer specializing in creating online experiences and makin’ things move – Ottawa, Canada" />
        <meta name="handheldfriendly" content="true" />
        <meta name="mobileoptimized" content="240" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="The Portfolio of Charlie Quinn" />
        <meta property="og:url" content="https://charliequinn.ca/" />       
        <meta property="og:site_name" content="Charlie Quinn" />
        <meta property="og:description" content="An independent graphic designer specializing in creating online experiences and makin' things move – Ottawa, Canada" />
        <meta name="twitter:card" content="An independent graphic designer specializing in creating online experiences and makin' things move – Ottawa, Canada" />
        <meta name="twitter:title" content="The Portfolio of Charlie Quinn" />
        <meta name="twitter:url" content="https://charliequinn.ca/" />
        <meta name="twitter:description" content="An independent graphic designer specializing in creating online experiences and makin' things move – Ottawa, Canada" />   
        <meta name="google-site-verification" content="8BB0SWjQQA9oIOSGyLXVWshQ7VJQvosy4izDLNx3tNE" />
      </Helmet>
      <header>
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <div style={{width:"300px", position: "relative", height: "100%"}}>
                <div className="wordmark transition">
<svg x="0px" y="0px" viewBox="0 0 525.8 43.8">
<g>
  <path class="st0" d="M286.2,29c0,4.3,0,8.7,0,13c-12,0-24,0-36,0c0-13.4,0-26.8,0-40.2c12,0,24,0,36,0c0,4.3,0,8.7,0,13
    c-6.7,0-13.3,0-20,0c0,0.9,0,1.8,0,2.9c3.9,0,7.9,0,11.7,0c0,2.8,0,5.5,0,8.4c-3.9,0-7.9,0-11.7,0c0,1,0,1.8,0,2.9
    C272.9,29,279.5,29,286.2,29z"/>
  <path class="st0" d="M222.5,29c0,4.3,0,8.7,0,13c-11.8,0-23.8,0-35.6,0c0-13.4,0-26.7,0-40.1c6,0,11.9,0,17.9,0c0,9,0,18,0,27.1
    C210.8,29,216.6,29,222.5,29z"/>
  <path class="st0" d="M89.6,28.1c0-8,0-16.1,0-24.1l0,0V1.8c-6.2,0-12.3,0-18.5,0c0,5.3,0,10.5,0,15.8c-1.4,0-2.7,0-4,0V1.8
    c-6.2,0-12.4,0-18.6,0V42h18.6V26.1c1.4,0,2.7,0,4,0c0,5.4,0,10.6,0,16h18.5V28.1L89.6,28.1z"/>
  <path class="st0" d="M44.1,24.1c-0.1,1.3-0.4,2.5-0.7,3.7C41,36.1,33.6,42.4,24.6,43c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0
    c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.5,0h-0.1l0,0C11.3,43,1.8,33.5,1.8,21.8c0-10.3,7.5-18.9,17.3-20.7c1.2-0.2,2.4-0.4,3.6-0.4
    c0.1,0,0.2,0,0.3,0h0.1l0,0c10.1,0,18.5,7,20.6,16.4c0.2,0.9,0.4,1.8,0.5,2.7H27.8c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.5-0.6-0.8-0.8-1.1
    l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1,0-0.1-0.1
    c-0.8-0.6-1.8-0.8-2.8-0.8c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1c1.9,0,3.7-1.1,4.5-2.8c0,0,0.1-0.1,0.1-0.2L44.1,24.1L44.1,24.1
    z"/>
  <path class="st0" d="M140.3,41.4c-0.2-0.6-0.4-1.3-0.6-1.8c-3.6-11.4-7.2-22.7-10.8-34.1l-0.4-1.1l-0.8-2.6h-21.5l-2.4,7.6
    c-3,9.6-6.2,19.3-9.2,28.9L93.4,42h16.4l0.1-0.4l0.4-1.1l0.3-0.9l0.4-1.1c0.1-0.3,0.2-0.6,0.3-0.8c0-0.1,0-0.1,0.1-0.2l0,0l0.1-0.3
    h0.3c0,0,0.1,0,0.2,0c0.9,0,1.8,0,2.8,0h5.4c0.8,0,1.8,0,2.6,0h0.1h0.3l0.6,2.3v0.1l0,0c0.2,0.6,0.4,1.1,0.5,1.7l0.1,0.2l0,0
    l0.2,0.5h16.4C140.4,41.8,140.4,41.6,140.3,41.4z M114.3,27.2c0.9-2.9,1.8-5.7,2.7-8.6c0.9,3,1.8,5.7,2.7,8.6H114.3z"/>
  <path class="st0" d="M183.1,41.6c-0.6-1.1-1.2-2.2-1.8-3.3c-0.8-1.5-1.6-3-2.4-4.4l-1.3-2.5l0.2-0.2l0,0l0.1-0.1
    c0.1-0.1,0.1-0.2,0.2-0.2l0.7-0.8c3-3.7,4.3-7.9,3.9-12.8c-0.4-4-1.9-7.4-4.8-10.3c-3.2-3.2-7.1-5.1-11.6-5.1
    c-5.8-0.1-11.6,0-17.4,0h-4.5v40.2h16v-5.9h2.7c0.2,0,0.4,0,0.6,0c0.1,0,0.1,0,0.2,0h0.3l1.2,2.5c0.5,0.9,0.8,1.8,1.3,2.7l0,0
    l0.5,0.9h16.4l0,0C183.2,41.8,183.1,41.7,183.1,41.6z M163.8,22.8c-0.8,0.2-1.7,0.1-2.5,0.2c-0.4,0-0.6,0-1,0v-8
    c1.1,0,2.2-0.1,3.3,0c1.7,0.3,2.7,1.4,3.1,3C167.2,20.1,165.9,22.3,163.8,22.8z"/>
  <rect x="227.2" y="1.8" class="st0" width="18.5" height="40.2"/>
  <path class="st0" d="M358.3,37c0.6-0.8,1.3-1.5,1.8-2.2c3.8-5.1,5.2-10.7,4-17c-1-5.3-3.7-9.5-7.9-12.8c-5.1-3.9-10.9-5.2-17.1-3.9
    c-5.5,1.1-10.1,4.1-13.2,8.8c-3.8,5.6-4.7,11.8-2.7,18.4c1.4,4.6,4.3,8.3,8.2,11.1c3.4,2.4,7.3,3.7,11.5,3.7c6.2,0,12.3,0,18.5,0
    l0,0h3.1L358.3,37z M343.4,27c-2.9,0-5.1-2.2-5.1-5.1c0-2.7,2.3-5,5-5c2.8,0,5.1,2.2,5,5C348.4,24.7,346.1,26.9,343.4,27z"/>
  <path class="st0" d="M409.3,1.8h-7.9l0,0c-3.1,0-6.3,0-9.5,0c0,0.3,0,0.5,0,0.6c0,7.5,0,14.9,0,22.4c0,0.3,0,0.6-0.1,0.9
    c-0.2,0.9-0.7,1.7-1.7,1.9c-0.9,0.4-1.8,0.2-2.7-0.6c-0.6-0.6-0.8-1.3-0.8-2.1c0-7.6,0-15.1,0-22.6c0-0.2,0-0.4,0-0.6
    c-3.1,0-6.4,0-9.5,0h-7.9l0,0v6.1c0,5.1,0,10.2,0,15.2c0,2.6,0.4,5.1,1.2,7.6c1.7,5,4.8,8.6,9.7,10.7c2.4,1,4.9,1.5,7.5,1.7
    c2.8,0.2,5.5-0.1,8.2-0.7c2.9-0.7,5.5-2.1,7.7-4.2s3.7-4.6,4.6-7.5c0.9-2.8,1.1-5.6,1.1-8.5c0-4.7,0-9.4,0-14.1l0,0L409.3,1.8
    L409.3,1.8z"/>
  <path class="st0" d="M432.9,42c-6.1,0-12.2,0-18.4,0c0-13.4,0-26.8,0-40.2c6.1,0,12.2,0,18.4,0C432.9,15.2,432.9,28.6,432.9,42z"/>
  <path class="st0" d="M462.7,1.8V17c-2.6-4.9-5.4-10.3-7.9-15.2H438V42h15.7V26.3c2.3,4.4,4.6,8.8,6.9,13.2l0,0l1.3,2.5
    c0.4,0,16.1,0,16.5,0c0-2.4,0-36.8,0-40.2C478.4,1.8,462.7,1.8,462.7,1.8z"/>
  <path class="st0" d="M508.2,1.8V17c-2.6-4.9-5.4-10.3-7.9-15.2h-16.7V42h15.8V26.3c2.3,4.4,4.6,8.8,6.9,13.2l0,0l1.3,2.5
    c0.4,0,16.1,0,16.5,0c0-2.4,0-36.8,0-40.2C524,1.8,508.2,1.8,508.2,1.8z"/>
</g>
</svg>
                </div> 
              </div>
            </Link>

            <a role="button" className={`navbar-burger burger ${mobileNavOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>

              <div className="navbar-burger-container">
                {!mobileNavOpen &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.391" height="22.298" viewBox="0 0 30.391 22.298"><g transform="translate(179.731 83.426)"><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -83.426)"/><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -74.037)"/><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -64.648)"/></g></svg>
                }
                {mobileNavOpen &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.979" height="23.979" viewBox="0 0 23.979 23.979"><g transform="translate(494.601 -854.159)"><rect className="a" width="30.391" height="3.521" transform="translate(-494.601 875.649) rotate(-45)"/><rect className="a" width="30.391" height="3.521" transform="translate(-492.112 854.159) rotate(45)"/></g></svg>
                }
              </div>
            </a>
          </div>

          <div className={`navbar-menu ${mobileNavOpen ? "is-active" : ""}`} >
            <div className="navbar-end">
              <Link to="/work" className="navbar-item is-size-4 transition">
                Work
              </Link>

              <Link to="/about" className="navbar-item is-size-4 transition">
                About
              </Link>
            </div>

          </div>
        </nav>
      </header>
    </div>
  )
}